import { useMutation, useQuery } from 'react-query';
import { TestPlanRepository } from '../repositories/test-plan.repository';
import { TestPlanOut } from "../interfaces/test-plan.interface";


export const useDeleteTestPlan = () => {
  const mutation = useMutation((data: { testPlanId: string, orgId: string, projectId: string }) => {
    return TestPlanRepository.deleteTestPlan(data)
  });
  return mutation;
}

export const useValidateRanges = () => {
  const mutation = useMutation((data: TestPlanOut) => {
    return TestPlanRepository.validateRanges(data);
  });
  return mutation;
};
