import { useMutation, useQuery } from 'react-query';
import { FormulationRepository } from '../repositories/formulation.repository';

export const useGetFormulations = (
  data: {
    organizationId?: string;
    projectId?: string;
  },
  enabled: boolean
): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getFormulations`, data.organizationId, data.projectId],
    FormulationRepository.getFormulations,
    {
      enabled: !!data.projectId && !!data.organizationId,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useDeleteFormulation = () => {
  return useMutation(
    (data: {
      organizationId: string;
      projectId: string;
      formulationIds: string[];
    }) => {
      return FormulationRepository.deleteFormulations(data);
    }
  );
};
