/** @jsxImportSource @emotion/react */
import { Badge, Button, Input, Tabs, Typography } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  FormulationType,
  useFormulations,
} from '../../_shared/context/formulations-context';
import { notification } from 'antd/lib';
import {
  DownloadOutlined,
  PlayCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  ExplorationStep,
  SidebarTabs,
  SidebarView,
} from '../../components/exploration/constants';
import TextArea from 'antd/es/input/TextArea';
import { ExplorationTree } from './exploration-tree';
import { ExplorationConstraintSetting } from '../../components/exploration/exploration-contraint-setting/exploration-constraint-setting';
import { SidebarMenuHeader } from '../../components/exploration/sidebar-menu-header';
import { ExplorationGoalSetting } from '../../components/exploration/exploration-goal-setting/exploration-goal-setting';
import { useExploration, useSession } from '../../_shared/context';
import { hasValue } from '../../_shared/utils/util';
import {
  IterationNote,
  usecreateOneIterationNoteMutation,
} from '../../../../__generated__/globalTypes';
import { Notes } from '../../components/exploration/exploration-notes-component';
import { css } from '@emotion/react';
import { useSearchParams } from 'react-router-dom';
import { GoalSimulation } from './exploration-goal-simulation';
import { useFeatureContext } from '../../_shared/context/features-context';
import { ProductFeatureType } from '@prisma/client';
import { useScenarioDetail } from '../../_shared/context/scenario-detail-context';
import { useDesign } from '../../_shared/context/design-context';

const { Text } = Typography;

export const ExplorationMenu = ({
  handleGenerateExperiment,
  handleGenerateCampaign,
  handleNextStep,
  handleChangeFormulationToView,
  formulationToView,
}: {
  handleGenerateExperiment: () => void;
  handleGenerateCampaign: () => void;
  handleNextStep: () => void;
  handleChangeFormulationToView: () => void;
  formulationToView: any;
}) => {
  const [searchParams] = useSearchParams();
  const {
    currentStep,
    setCurrentSidebarTab,
    currentSidebarTab,
  } = useExploration();
  const { constraints, hasNewConstraints } = useScenarioDetail();
  const { previousDesigns, latestDesign, quickDesignIsRunning } = useDesign();
  const { formulationsFetching } = useFormulations();
  const { getSelectedIteration, currentProject } = useSession();
  const [currentSidebarView, setCurrentSidebarView] = useState<SidebarView>(
    SidebarView.TABS
  );
  const [createOneIterationNote] = usecreateOneIterationNoteMutation();
  const [iterationNotes, setIterationNotes] = useState<IterationNote[]>([]);
  const [newNoteTitle, setNewNoteTitle] = useState<string | undefined>();
  const [newNoteText, setNewNoteText] = useState<string | undefined>();

  const { isEnableFeature } = useFeatureContext();

  useEffect(() => {
    const iterationNotes = getSelectedIteration()?.iterationNotes;
    if (iterationNotes) {
      setIterationNotes(iterationNotes as IterationNote[]);
    }
  }, [getSelectedIteration()?.iterationNotes]);

  useEffect(() => {
    const path = window.location.pathname;
    const tabFromPath = path.split('/').pop();
    switch (tabFromPath) {
      case 'goal':
        setCurrentSidebarTab(SidebarTabs.GOAL_SETTINGS);
        break;
      case 'notes':
        setCurrentSidebarTab(SidebarTabs.NOTES);
        break;
      case 'constraints':
        setCurrentSidebarTab(SidebarTabs.CONSTRAINTS);
        break;
      case 'goal_simulation':
        setCurrentSidebarTab(SidebarTabs.FORECAST);
        break;
      default:
        // Set a default tab or handle unknown paths
        setCurrentSidebarTab(SidebarTabs.GOAL_SETTINGS);
    }
  }, [window.location.pathname, setCurrentSidebarTab]);

  const handleCreateNewNote = async () => {
    if (hasValue(newNoteTitle) && hasValue(newNoteText)) {
      const createOneNoteResponse = await createOneIterationNote({
        variables: {
          title: newNoteTitle as string,
          text: newNoteText as string,
          iterationId: getSelectedIteration()?.id!,
        },
      });
      if (createOneNoteResponse.data?.createOneIterationNote) {
        setIterationNotes([
          ...iterationNotes,
          createOneNoteResponse.data?.createOneIterationNote as IterationNote,
        ]);
      }

      setNewNoteText(undefined);
      setNewNoteTitle(undefined);
      setCurrentSidebarView(SidebarView.TABS);
    } else {
      notification.error({ message: 'Note Title and Body are required' });
    }
  };

  const FormButtonStyles = css`
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  `;

  const showProminentGetRecommendationsButton =
    !formulationsFetching &&
    !quickDesignIsRunning &&
    hasNewConstraints &&
    constraints.length >= 1 &&
    (previousDesigns.length === 1 ||
      (previousDesigns.length === 0 && latestDesign));

  return (
    <>
      <div id="exploration-tree-content">
        <div id="exploration-tree-title-search">
          <span className="exploration-section-header-text">
            Discover Solutions
          </span>
          {/* <Button
            className="menu-button"
            type="text"
            style={{ marginTop: 25 }}
            onClick={toggleSidebar}
            icon={
              <DownloadOutlined rotate={90} style={{ color: '#b1b5b8' }} />
            }
          /> */}
        </div>
        <Tabs
          className="exploration-tabs"
          defaultActiveKey="experiments"
          onChange={activeKey => setCurrentSidebarTab(activeKey as SidebarTabs)}
          activeKey={currentSidebarTab}
          items={[
            {
              key: SidebarTabs.FORMULATIONS,
              label: (
                <>
                  <span onClick={() => handleNextStep()}>Formulations</span>
                </>
              ),
              children: (
                <div className="discover-solutions-tab-content exploration-tab-container">
                  <ExplorationTree
                    handleChangeFormulationToView={
                      handleChangeFormulationToView
                    }
                    selectedFormulation={formulationToView}
                  />
                </div>
              ),
            },
            {
              key: SidebarTabs.GOAL_SETTINGS,
              label: (
                <>
                  <span>Goal</span>
                </>
              ),
              children: (
                <div className="discover-solutions-tab-content exploration-tab-container">
                  <ExplorationGoalSetting />
                </div>
              ),
            },
            {
              key: SidebarTabs.CONSTRAINTS,
              label: (
                <>
                  <span>Constraints</span>
                </>
              ),
              children: (
                <div className="discover-solutions-tab-content exploration-tab-container">
                  <ExplorationConstraintSetting />
                </div>
              ),
            },
            {
              key: SidebarTabs.NOTES,
              label: (
                <>
                  <span>Notes</span>
                  <Badge
                    style={{ marginLeft: 5 }}
                    count={iterationNotes.length}
                    size="small"
                  />
                </>
              ),
              children: (
                <div className="discover-solutions-tab-content exploration-tab-container">
                  {currentSidebarView !== SidebarView.CREATE_NOTE && (
                    <Notes
                      iterationNotes={iterationNotes}
                      setShowNewNotesForm={() =>
                        setCurrentSidebarView(SidebarView.CREATE_NOTE)
                      }
                    />
                  )}
                  {currentSidebarView === SidebarView.CREATE_NOTE && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly',
                          width: '100%',
                        }}
                      >
                        <SidebarMenuHeader
                          handleOnClick={() =>
                            setCurrentSidebarView(SidebarView.TABS)
                          }
                          title="New Note"
                        />
                        <div>
                          <span
                            css={css`
                              font-size: 14px;
                              padding-bottom: 10px;
                              font-family: Inter;
                            `}
                          >
                            Title
                          </span>
                          <Input
                            style={{
                              marginTop: '10px',
                              marginBottom: '10px',
                            }}
                            placeholder="Type here"
                            onChange={event =>
                              setNewNoteTitle(event.target.value)
                            }
                          />
                        </div>
                        <div>
                          <span
                            css={css`
                              font-size: 14px;
                              padding-bottom: 10px;
                              font-family: Inter;
                            `}
                          >
                            Body
                          </span>
                          <TextArea
                            style={{ marginTop: '10px' }}
                            placeholder="Type here"
                            onChange={event =>
                              setNewNoteText(event.target.value)
                            }
                            rows={6}
                          />
                        </div>
                      </div>
                      <div css={FormButtonStyles}>
                        <Button
                          danger
                          onClick={() =>
                            setCurrentSidebarView(SidebarView.TABS)
                          }
                        >
                          Delete
                        </Button>

                        <Button
                          icon={<SaveOutlined />}
                          onClick={() => handleCreateNewNote()}
                          block
                        >
                          Save Note
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ),
            },
          ].concat(
            isEnableFeature[ProductFeatureType.GOAL_SIMULATION]
              ? {
                key: SidebarTabs.FORECAST,
                label: (
                  <>
                    <span>Forecast</span>
                  </>
                ),
                children: (
                  <div className="discover-solutions-tab-content exploration-tab-container">
                    <div id="goal-simulation">
                      <GoalSimulation />
                    </div>
                  </div>
                ),
              }
              : []
          )}
        />
      </div>

      {currentSidebarView === SidebarView.TABS &&
        currentSidebarTab === SidebarTabs.NOTES && (
          <Button
            style={{ width: '100%' }}
            icon={<PlusOutlined />}
            onClick={() => setCurrentSidebarView(SidebarView.CREATE_NOTE)}
          >
            New Note
          </Button>
        )}
      {currentSidebarTab !== SidebarTabs.NOTES && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          <Button
            id="generate-experiments-button"
            type="primary"
            icon={<PlayCircleOutlined />}
            onClick={handleGenerateCampaign}
            disabled={
              currentStep !== ExplorationStep.FORMULATION_DISCOVERY ||
              (currentProject?.currentCampaignId !== null &&
                currentProject?.currentCampaignId !== undefined)
            }
            // loading={
            //   runDesignIsLoading || designState === DesignStates.RUNNING
            // }
            hidden={currentSidebarTab === SidebarTabs.FORMULATIONS}
          >
            Generate Initiative
          </Button>

          <Button
            id="generate-experiments-button"
            type="primary"
            icon={<PlayCircleOutlined />}
            onClick={handleGenerateExperiment}
            disabled={currentStep !== ExplorationStep.FORMULATION_DISCOVERY}
            hidden={currentSidebarTab === SidebarTabs.FORMULATIONS}
            css={
              currentStep === ExplorationStep.FORMULATION_DISCOVERY &&
                showProminentGetRecommendationsButton
                ? css`
                    box-shadow: 0 0 15px rgba(255, 215, 0, 0.7);
                  `
                : undefined
            }
          >
            Generate Recommendations
          </Button>
        </div>
      )}
    </>
  );
};
