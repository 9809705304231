import { BaseClient } from '../ApiClient';
import {
  IProjectCreateOut,
  IProjectEnableGoalSim,
} from '../interfaces/project.interfaces';
import { IFormulationRaw } from '../interfaces/formulationRaw.interfaces';

const ENDPOINTS = {
  postCreateProjectUrl: (id: string) => `org/${id}/project`,
  postFinishSetuprojectUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/finish`,
  postUploadRawFormulationsUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/uploadRawData`,
  deleteRawFormulationsUrl: (
    id: string,
    projectId: string,
    formulationId: string
  ) => `org/${id}/project/${projectId}/delete/formulation/${formulationId}`,
  getProjectByIdUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}`,
  getProjectFormulationsRawUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/formulationsRaw`,
  getProjectDuplicate: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/duplicate`,
  getProjectSummary: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/summary`,
  postClearDetails: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/clearDetails`,
  getProjectGoalSimulatorStatusUrl: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/enableGoalSimulator`,
  getEventHistory: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/history`,
};

const postCreateProject = (data: IProjectCreateOut): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postCreateProjectUrl(data.organizationId),
    data
  );
};

const postFinishSetupProject = (data: IProjectCreateOut): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postFinishSetuprojectUrl(
      data.organizationId,
      data.id ? data.id : ''
    ),
    data
  );
};

const postUploadRawFormulations = (data: IFormulationRaw): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postUploadRawFormulationsUrl(data.orgId, data.projectId),
    data
  );
};

const deleteRawFormulations = (data: IFormulationRaw): Promise<any> => {
  return BaseClient.delete(
    ENDPOINTS.deleteRawFormulationsUrl(
      data.orgId,
      data.projectId,
      data.formulationId!
    ),
    data
  );
};
const getProjectById = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectByIdUrl(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const getProjectFromulationRaw = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectFormulationsRawUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};
const postDuplicateProject = (data: any): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.getProjectDuplicate(data.orgId, data.projectId),
    {
      userId: data.user.id,
      duplicateWithModel: data.duplicateWithModel,
    }
  );
};

const getProjectSummary = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectSummary(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const getProjectGoalSimulatorStatus = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getProjectGoalSimulatorStatusUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

const postProjectEnableGoalSimulator = (
  data: IProjectEnableGoalSim
): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.getProjectGoalSimulatorStatusUrl(
      data.organizationId,
      data.projectId
    ),
    { enableGoalSimulator: data.enableGoalSimulator }
  );
};

const postClearData = (data: any): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.postClearDetails(data.orgId,
      data.projectId), {})
}

const getEventoHistory = (data: any) => {
  console.log("EVENTHISTORU", data)
  return BaseClient.get(ENDPOINTS.getEventHistory(data.queryKey[1],
    data.queryKey[2]))
}

export const ProjectRepository = {
  postCreateProject,
  getProjectById,
  getProjectFromulationRaw,
  postFinishSetupProject,
  postUploadRawFormulations,
  deleteRawFormulations,
  postDuplicateProject,
  getProjectSummary,
  getProjectGoalSimulatorStatus,
  postProjectEnableGoalSimulator,
  postClearData,
  getEventoHistory
};
