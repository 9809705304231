import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  getFormulation: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/formulation`,
  deleteFormulations: (id: string, projectId: string) =>
    `org/${id}/project/${projectId}/formulation`,
};

const getFormulations = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getFormulation(parameters.queryKey[1], parameters.queryKey[2])
  );
};

const deleteFormulations = (data: {
  organizationId: string;
  projectId: string;
  formulationIds: string[];
}): Promise<any> => {
  return BaseClient.post(
    ENDPOINTS.deleteFormulations(data.organizationId, data.projectId),
    {
      formulationIds: data.formulationIds,
    }
  );
};

export const FormulationRepository = {
  getFormulations,
  deleteFormulations,
};
