import { BaseClient } from '../ApiClient';
const ENDPOINTS = {
  getGoalProjectDashboardUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/goal/dashboard`,
  getGoalProgresDesirabilityUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/goal/progress/desirability`,
  getGoalProgresDesirabilityOutcomeUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/goal/progress/outcome/desirability`,
  getForecastGoalSimulation: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/goal/forecast`,
  getFormulationGoalUrl: (org_id: string, proj_id: string, formId: string) =>
    `org/${org_id}/project/${proj_id}/goal/formulationid/${formId}`,
};

const getGoalProjectDashboard = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getGoalProjectDashboardUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};
const getGoalProgresDesirability = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getGoalProgresDesirabilityUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};
const getForecastGoalSimulation = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getForecastGoalSimulation(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

const useGetGoalsProgressDesirabilityOutcome = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getGoalProgresDesirabilityOutcomeUrl(
      parameters.queryKey[1],
      parameters.queryKey[2]
    )
  );
};

const getFormulationGoal = (parameters: any) => {
  return BaseClient.get(
    ENDPOINTS.getFormulationGoalUrl(
      parameters.queryKey[1],
      parameters.queryKey[2],
      parameters.queryKey[3]
    )
  );
};
const postGoalSimulationFromUI = (parameters: {
  organizationId?: string;
  projectId?: string;
  userId?: string;
}) => {
  return BaseClient.post(
    ENDPOINTS.getForecastGoalSimulation(
      parameters.organizationId!,
      parameters.projectId!
    ), { userId: parameters.userId }
  );
};

export const GoalRepository = {
  getGoalProjectDashboard,
  getGoalProgresDesirability,
  useGetGoalsProgressDesirabilityOutcome,
  getForecastGoalSimulation,
  getFormulationGoal,
  postGoalSimulationFromUI
};
