import { Button, Form, Modal } from "antd"
import React, { useState } from "react"

type modalProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export const SoftDeleteModal = ({ onClose, onConfirm }: modalProps) => {

  const [loading, setLoading] = useState<boolean>(false)

  const cancelBtn = (
    <Button key="back" onClick={onClose}>
      Cancel
    </Button>
  );

  const onClickConfirm = () => {
    setLoading(true);
    onConfirm();
  }

  const confirmDeleteBtn = (
    <Button
      className='primary-button'
      htmlType="submit"
      form="delete-form"
      key="confirm"
      onClick={() => onClickConfirm()}
      loading={loading}
    >
      Confirm
    </Button>
  );

  const footer = [
    cancelBtn,
    confirmDeleteBtn
  ];

  return (<>
    <Modal
      title="Soft deleting initiative"
      onCancel={onClose}
      open={true}
      footer={footer}
      centered
    >
      <p>Are you sure you want to soft delete this round? The <b>entire initiative</b> will be deleted, including all its rounds. </p>
      <p>This initiative can be restored or deleted permanently. </p>
    </Modal>
  </>)
}