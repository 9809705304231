import { ProductFeatureType } from "@prisma/client";
import { useGetProductFeature } from "../../network/services/features.service";
import React, { useEffect, useState } from "react";
import { createContext, ReactNode, useContext } from "react";
import { useSession } from "./session-context";

interface FeautreContextProps {
  isEnableFeature: { [key in ProductFeatureType]: boolean }
}

const FeautreContext = createContext<FeautreContextProps | undefined>(
  undefined
);

export const useFeatureContext = () => {
  const context = useContext(FeautreContext);
  if (!context) {
    throw new Error(
      'FeautreContext must be used within an FeautreContextProvider'
    );
  }
  return context;
};

interface FeautreContextProviderProps {
  children: ReactNode;
}

export const FeatureContextProvider: React.FC<FeautreContextProviderProps> = ({
  children,
}) => {

  const baseFeatures: { [key in ProductFeatureType]: boolean } = {
    GOAL_SIMULATION: false,
    TURING_UNIVERSITY: false
  }

  const [isEnableFeature, setIsEnableFeature] = useState(baseFeatures)
  const { user } = useSession();

  const { data: features, isSuccess } = useGetProductFeature(user)

  useEffect(() => {

    if (features && isSuccess && user) {

      console.log(features)
      const featuresEnableMap = features.data
      const enumedEnabledFeaturesToSet = Object.keys(featuresEnableMap).reduce((acc, key) => {
        if (key in ProductFeatureType) {
          acc[ProductFeatureType[key as keyof typeof ProductFeatureType]] = !featuresEnableMap[key];
        }
        return acc;
      }, {} as { [key in ProductFeatureType]: boolean });

      if (featuresEnableMap) {
        setIsEnableFeature(featuresEnableMap)
      }


      console.log(enumedEnabledFeaturesToSet)
    }
  }, [features])

  return (
    <FeautreContext.Provider value={{
      isEnableFeature
    }}>
      {children}
    </FeautreContext.Provider>
  )

}