import { useQuery } from "react-query";
import { ProductFeatureRepository } from "../repositories/features.repository";
import { BaseResponseInterface } from "../interfaces/base.interface";
import { ProductFeatureType } from "@prisma/client";
import { User } from "__generated__/globalTypes";

export const useGetProductFeature = (user?: User): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any
  refetch: () => void;
} => {
  return useQuery(
    [`getProductFeature`],
    ProductFeatureRepository.getProductFeatures,
    {
      enabled: !!user,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};