import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { useExploration, useSession } from '../../../_shared/context';
import {
  FormulationStatus,
  useAddFolderToFormulationMutation,
  useRemoveFolderFromFormulationMutation,
} from '../../../../../__generated__/globalTypes';
import { Button, Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { CampaignModal } from '../../../components/campaign/campaignModal';
import {
  useGetCampaign,
  usePutCampaign,
  useSoftDeleteCampaign,
} from '../../../network/services/campaign.service';
import { notification } from 'antd/lib';
import { SoftDeleteModal } from './soft-delete-modal';
const { Search } = Input;

interface ExperimentFolderProps {
  folder: {
    name: string;
    key: string;
    campaignId: string | undefined;
    isLoading: boolean;
  },
  isEditMode?: boolean,
  openedFolder?: boolean,
  toogleEditMode: (id: string) => void,
  editFolderName: (updateFolderData: {
    id: string,
    newName: string
  }) => void,
  deleteFolder: (id: string) => void;
  handleExpandFolder: (folderKey?: string) => void;
  softDeleteFolder: (id: string) => void;
  editableFolder?: boolean,
  isFeedbackFolder?: boolean
};

export const ExperimentFolder: React.FC<ExperimentFolderProps> = ({
  folder,
  isEditMode,
  openedFolder,
  toogleEditMode,
  editFolderName,
  deleteFolder,
  handleExpandFolder,
  softDeleteFolder,
  editableFolder,
  isFeedbackFolder,
}) => {
  const folderStyle = { color: '#b1b5b8', fontSize: 20 };
  const iconStyles = { color: '#b1b5b8', width: '100%' };
  const { setFormulationStatus } = useExploration();
  const [addFormulationToFolder] = useAddFolderToFormulationMutation();
  const [removeFormulationToFolder] = useRemoveFolderFromFormulationMutation();
  const [editCampaign, setEditCampaign] = useState<boolean>();
  const updateCampaign = usePutCampaign();
  const [opened, setOpened] = useState(false);
  const { useFetchProject, currentProject } = useSession();
  const [fetchProjectById] = useFetchProject();
  const [deleteModal, setDeleteModal] = useState<string | undefined>();
  const [isSoftDeleted, setIsSoftDeleted] = useState<boolean>(false);
  const { user } = useSession();

  const { data, isLoading, refetch } = useGetCampaign(
    { campaignId: folder?.campaignId!! },
    false
  );
  const softDeleteMutation = useSoftDeleteCampaign();

  useEffect(() => {
    refetch();
  }, []);

  const [{ isOver }, drop] = useDrop({
    accept: 'formulationKey',
    drop: (item: { formulationKey: any }) => {
      handleOnFormulationDrop(item.formulationKey);
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.getDropResult(),
    }),
  });

  const handleOnFormulationDrop = async (data: any) => {
    console.log(data, folder.name, folder.key);
    if (data.pinned) {
      const formulation = await removeFormulationToFolder({
        variables: {
          id: data.formulationRecord.id,
        },
      });
      data.formulationRecord.folderId = null;
      setFormulationStatus(data.formulationRecord, FormulationStatus.NEW);
    } else {
      const formulation = await addFormulationToFolder({
        variables: {
          folderId: folder.key,
          id: data.formulationRecord.id,
        },
      });
      data.formulationRecord.folderId = folder.key;
      setFormulationStatus(data.formulationRecord, FormulationStatus.PINNED);
    }
  };

  const toggleCampaignModal = () => {
    setEditCampaign(prevState => !prevState);
  };

  const handleConfirmCampaign = async (data: any) => {
    await updateCampaign.mutate(
      {
        campaignId: folder?.campaignId,

        numberIterations: data.number_rounds,
        numberFormulations: data.number_formulations,
      },
      {
        onSuccess: async response => {
          toggleCampaignModal();
          if (response.data.status) {
            await fetchProjectById({
              variables: {
                projectId: `${currentProject?.id}`,
              },
            });
            notification.success({ message: response.data.message });
          }
        },
      }
    );
  };

  const handleSoftDeleteFolder = (folderId: string) => {
    setDeleteModal(folder.campaignId);
  }

  const handleConfirmSoftDelete = async () => {
    if (deleteModal)
      await softDeleteMutation.mutate(
        {
          campaignId: deleteModal
        },
        {
          onSuccess: res => {
            setDeleteModal(undefined);
            setIsSoftDeleted(true);
            console.log('DELETED:', deleteModal)
          }
        }
      )
  }

  const handleCancelSoftDelete = () => {
    setDeleteModal(undefined)
  }

  const handleExpand = () => {
    console.log(opened);
    setOpened(!opened);
  };

  return !isSoftDeleted && <>
    <div
      style={{
        display: 'flex'
      }}
      ref={drop}
      onClick={handleExpand}
    >
      {opened ? (
        <FolderOpenOutlined style={folderStyle} />
      ) : (
        <FolderOutlined style={folderStyle} />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {!isEditMode ? (
          <span
            style={{
              marginLeft: '1rem',
            }}
          >
            {folder.name ?? 'New Folder'}
          </span>
        ) : (
          <div
            style={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Search
              style={{ width: '100%', padding: '0.5rem' }}
              placeholder={folder.name}
              allowClear
              enterButton="Save"
              size="large"
              onSearch={value =>
                editFolderName({
                  id: folder.key,
                  newName: value,
                })
              }
            />
          </div>
        )}

        <div
          style={{
            width: '25%',
          }}
        >
          {!isEditMode && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              {!folder.campaignId && !isFeedbackFolder && (
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => toogleEditMode(folder.key)}
                  style={iconStyles}
                />
              )}
              {folder.campaignId && !isFeedbackFolder && (
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => setEditCampaign(true)}
                  style={iconStyles}
                />
              )}
              {!folder.campaignId && (
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => deleteFolder(folder.key)}
                  style={iconStyles}
                />
              )}
              {folder.campaignId && user && data && (
                user.id === data.createdById ? (
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleSoftDeleteFolder(folder.key)}
                    style={iconStyles}
                  />
                ) : (
                  <Tooltip title="Only owner of the initiative can delete." placement="top">
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      disabled
                      style={iconStyles}
                    />
                  </Tooltip>
                )
              )}
              <Button
                type="text"
                icon={<ExportOutlined />}
                style={iconStyles}
                onClick={() => handleExpandFolder()}
                disabled={folder.isLoading}
              />
            </div>
          )}
        </div>
      </div>
      {editCampaign && data && (
        <CampaignModal
          onClose={toggleCampaignModal}
          refeshProject={false}
          onConfirm={handleConfirmCampaign}
          data={data}
        />
      )}
      {deleteModal && (
        <SoftDeleteModal onClose={handleCancelSoftDelete} onConfirm={handleConfirmSoftDelete} />
      )}
    </div>
  </>
};
