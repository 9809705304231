import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  postCreateCampaign: () =>
    `org/campaign`,
  getCampaign: (campaignId: string) =>
    `org/campaign/${campaignId}`,
  putCampaign: (campaignId: string) =>
    `org/campaign/${campaignId}`,
  softDeleteCampaign: (campaignId: string) =>
    `org/campaign/softDelete/${campaignId}`
};

const postCreateCampaign = (data: any) => {
  return BaseClient.post(
    ENDPOINTS.postCreateCampaign(),
    data
  );
};
const getCampaign = (data: any) => {
  if (data.queryKey[1] !== 'FEEDBACK')
    return BaseClient.get(
      ENDPOINTS.getCampaign(data.queryKey[1])
    );
};
const putCampaign = (data: any) => {
  return BaseClient.put(
    ENDPOINTS.putCampaign(data.campaignId), {
    numberIterations: data.numberIterations,
    numberFormulations: data.numberFormulations
  }
  );
};
const softDeleteCampaign = (data: { campaignId: string }) => {
  return BaseClient.patch(
    ENDPOINTS.softDeleteCampaign(data.campaignId))
}


export const CampaignRepository = {
  postCreateCampaign,
  getCampaign,
  putCampaign,
  softDeleteCampaign
};
