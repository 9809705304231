/** @jsxImportSource @emotion/react */
import './formulation-generated-style.less';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { useSession } from '../../../_shared/context';
import { useDesign } from '../../../_shared/context/design-context';
import {
  FormulationItemType,
  ProjectFeature,
} from '../../../../../__generated__/globalTypes';
import { Flex, Typography } from 'antd';
import { css } from '@emotion/react';
import { useIngredients } from '../../../_shared/hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export const ExplorationTimelineFormulationItemsList = ({
  data,
  design,
}: {
  data: Record<string, any>;
  design: string | undefined | null;
  isBenchmark?: boolean;
}) => {
  const { currentProject } = useSession();
  const { previousDesigns } = useDesign();

  const [costEnable, setCostEnable] = useState(false);

  const topObjectivesSet = new Set();
  const associatedDesign = previousDesigns.filter(d => d?.id === design)?.[0];
  if (!associatedDesign) {
    currentProject?.activeModel?.outcomes.forEach(o => {
      topObjectivesSet.add(o.targetVariable);
    });
  }

  associatedDesign?.objectives
    .sort((a, b) => b.importance - a.importance)
    .slice(0, 9)
    .forEach(o => {
      topObjectivesSet.add(o.targetVariable);
    });

  useEffect(() => {
    if (currentProject) {
      let find = false;
      currentProject.features.map(f => {
        if (f.feature === ProjectFeature.PRICING) {
          find = true;
        }
      });
      setCostEnable(find);
    }
  }, [currentProject]);

  const formulationRecord = data.formulationRecord as FormulationType;

  return (
    <div
      className="formulation-generated"
      css={css`
        padding-top: ${costEnable ? '75px' : '66px;'};
      `}
    >
      {costEnable && (
        <div
          css={css`
            color: #161f26;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 8px;
          `}
        >
          Cost:
        </div>
      )}
      <Flex justify="space-between">
        <Flex gap={5} align="center" style={{ padding: '10px 0' }}>
          <div
            css={css`
              color: #161f26;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            `}
          >
            Ingredients:
          </div>
        </Flex>
      </Flex>
      {formulationRecord.items
        .filter(
          item =>
            item.type !== FormulationItemType.TARGET_MEASURED &&
            item.type !== FormulationItemType.TARGET_PREDICTED
        )
        .map(item => {
          return (
            <div className="row">
              <div className="ingredient" style={{ overflow: 'auto' }}>
                <Typography.Text
                  style={{ color: 'inherit', fontSize: 'inherit' }}
                  ellipsis={{
                    tooltip: item.variable.name,
                  }}
                >
                  {item.variable.name}
                </Typography.Text>
              </div>
            </div>
          );
        })}
      <Flex justify="space-between">
        <Flex gap={5} align="center" style={{ padding: '11px 0' }}>
          <div
            css={css`
              color: #161f26;
              font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            `}
          >
            Outcomes:
          </div>
        </Flex>
      </Flex>
      {currentProject?.activeModel?.outcomes
        .filter(o => topObjectivesSet.has(o.targetVariable))
        .map(outcome => {
          return (
            <div className="row">
              <div className="ingredient" style={{ overflow: 'auto' }}>
                <Typography.Text
                  style={{ color: 'inherit', fontSize: 'inherit' }}
                  ellipsis={{
                    tooltip: outcome?.targetVariable,
                  }}
                >
                  {outcome?.targetVariable}
                </Typography.Text>
              </div>
            </div>
          );
        })}
    </div>
  );
};
