import { Button, Form, Modal } from "antd"
import React from "react"

type modalProps = {
  onClose: () => void;
  onConfirm: () => void;
};

export const DeleteTestPlanModal = ({ onClose, onConfirm }: modalProps) => {

  const cancelBtn = (
    <Button key="back" onClick={onClose}>
      Cancel
    </Button>
  );

  const confirmDeleteBtn = (
    <Button
      className='primary-button'
      htmlType="submit"
      form="delete-form"
      key="confirm"
      onClick={() => onConfirm()}
    >
      Confirm
    </Button>
  );

  const footer = [
    cancelBtn,
    confirmDeleteBtn
  ];

  return (<>
    <Modal
      title="Delete test plan?"
      onCancel={onClose}
      open={true}
      footer={footer}
      centered
    >
      <p>Are you sure you want to <b>delete this test plan?</b></p>
    </Modal>
  </>)
}