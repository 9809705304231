import { css } from '@emotion/react';
import { Colors } from '../../../../../_shared/style';

export const reportItemStyle = css`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-top-color: transparent;
  border-bottom-color: transparent;
  padding-left: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: ${Colors.ARSENIC};
  display: block;
  min-height: 32px;
  cursor: pointer;
  outline-style: none;

  &:hover {
    color: ${Colors.BRANDEIS_BLUE};
  }

  &.selected {
    width: calc(100% + 1px);
    border-left: 2px solid red;
    border-right: 1px solid ${Colors.WHISPER};
    background-color: ${Colors.WHISPER};
    border-top-color: ${Colors['#EBEAF0']};
    border-bottom-color: ${Colors['#EBEAF0']};

    a {
      color: ${Colors.BRANDEIS_BLUE};
    }
  }

  &:last-child {
    border-bottom: 1px solid transparent;
    &.selected {
      border-bottom-color: ${Colors['#EBEAF0']};
    }
  }
`;
