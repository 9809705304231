import React, { FC, useEffect, useState } from 'react';
import './upload-data.less';
import { Button, Drawer, message, Radio, Space, Tabs, UploadProps } from 'antd';
import { ConstraintType } from '../../../../../../__generated__/globalTypes';
import { InputOutcomesSetup } from '../../../../components/exploration/constants';
import { ProjectOutcomesTable } from '../project-outcomes-table/project-outcomes-table';
import { ProjectInputTable } from '../project-inputs-table/project-inputs-table';
import {
  useInputOutpuNamesContext,
  useSession,
} from '../../../../_shared/context';
import { RadioChangeEvent } from 'antd/lib';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { parse } from 'papaparse';
import { useUploadFormulationRaw } from '../../../../network/services/project.service';
import { useDownladCSVTemplate } from '../../../../network/services/file.service';
import {
  IBaseFormulationRaw,
  IFormulationRaw,
} from '../../../../network/interfaces/formulationRaw.interfaces';

export const emptyConstraint = {
  id: '',
  constraintType: ConstraintType.EQUALITY,
  lowerBounds: null,
  upperBounds: null,
  coefficients: [],
  values: [],
  variables: [],
};

export const ProjectUploadDataStep = ({
  projectId,
  enableNextStep,
  setNoData,
  projectHasData,
}: {
  projectId: string;
  enableNextStep: (value: boolean) => void;
  setNoData: (value: boolean) => void;
  projectHasData: (value: boolean) => void;
}) => {
  const [value, setValue] = useState('upload');
  const [fileHasErrors, setFileHasErrors] = useState<boolean>(false);
  const [initUpload, setInitUpload] = useState<boolean>(false);
  const [fileData, setFileData] = useState<RcFile>();
  let rowNames = new Set<string>(['FormulationID', 'Type']);
  const [formulationsFromCsv, setFormulationsFromCsv] = useState<
    Array<IBaseFormulationRaw>
  >([]);
  const uploadFormulationRaw = useUploadFormulationRaw();
  const {
    data,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useDownladCSVTemplate();
  const { user, setCurrentProject } = useSession();
  const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
    if (e.target.value !== 'upload') {
      setNoData(true);
      projectHasData(false);
      enableNextStep(true);
    } else {
      projectHasData(true);
      enableNextStep(initUpload);
    }
  };

  let csvData: number = 0;
  let invalidRowNames: string[] = [];
  const inconsistentErrors: string[] = [];
  let auxFormulationsFromCsv: any[] = [];
  let headerAmount = 0;
  const inconsistentFormulation: string[] = [];

  const validateFile = (file: RcFile) => {
    return new Promise<RcFile>((resolve, reject) => {
      parse<Array<string>>(file, {
        header: true,
        skipEmptyLines: 'greedy',
        step: (results: any) => {
          const { data } = results;
          const { fields } = results.meta;
          let rowName: string | string[] = getResultsRowName(data);
          const isValid = validateRowNames(results.meta.fields);
          headerAmount = fields.length;
          rowNames = new Set([...rowNames, ...fields]);
          const emptyOutcomeData = validateIfEmptyValues(results.data);

          Object.entries(data).forEach(([key, value]) => {
            if (String(value).includes(',')) {
              inconsistentErrors.push(
                `Error: The file contains invalid entries. Formulation names or variable names cannot include commas. Please remove any commas and try uploading again.`
              );
            }
          });

          if (emptyOutcomeData.length > 0) {
            inconsistentErrors.push(
              `No outcome data for: ${emptyOutcomeData.join(' ')} in ${
                data.FormulationID
              }`
            );
          }

          if (headerAmount != Object.keys(results.data).length) {
            inconsistentFormulation.push(results.data.FormulationID);
          }

          if (!isValid) {
            invalidRowNames.push(rowName);
          } else {
            const rowData = Object.entries(data).filter(
              (val, idx) => idx !== 0
            );
            let cellCount = 0;
            rowData.forEach(rd => {
              if (rd[1]) cellCount++;
            });

            if (cellCount > 0) {
              csvData++;
              auxFormulationsFromCsv.push(data);
            }
          }
        },
        complete: (results: any) => {
          const { errors } = results;
          //const boundsValidation = validateBounds()
          //console.log(boundsValidation)

          if (csvData === 0) {
            setFileHasErrors(true);
            void message.error('Data file has no data rows');
            reject({});
          } else if (inconsistentFormulation.length > 0) {
            setFileHasErrors(true);
            void message.error(
              'Not the same ammount of outcomes for: ' +
                inconsistentFormulation.join(',')
            );
            reject({});
          } else if (inconsistentErrors.length > 0) {
            setFileHasErrors(true);
            void message.error(inconsistentErrors.join('|'));
            reject({});
          }
          // else if (!boundsValidation.isValid) {
          //   setFileHasErrors(true);
          //   void message.error(
          //     `Lower and Upper bounds must be different for: ${boundsValidation.where}`
          //   );
          //   reject({});
          // }

          if (invalidRowNames.length > 0) {
            setFileHasErrors(true);
            // void message.error(
            //   `Attribute${invalidRowNames.length === 1 ? '' : 's'
            //   } '${invalidRowNames.join(
            //     ', '
            //   )}' not recognized in this project. Please download the CSV file template and try again.`
            // );
            void message.error(
              `Missing FormulationID or Type column. Please download the CSV file template and try again.`
            );
            invalidRowNames = [];
            auxFormulationsFromCsv = [];
            reject({});
          } else if (csvData === 0) {
            setFileHasErrors(true);
            void message.error(
              `Empty file detected. Please upload a file with values for at least one formulation.`
            );
            auxFormulationsFromCsv = [];
            reject({});
          }

          if (errors.length === 0) {
            setFileData(file);
            setFileHasErrors(false);
            setInitUpload(true);
            resolve(file);
          } else {
            setFileHasErrors(true);
            void message.error(
              `Error parsing file. Please upload a valid CSV file.`
            );
            auxFormulationsFromCsv = [];
            reject({});
          }
        },
      });
    });
  };

  const getResultsRowName = (data: string[][]) => {
    // The header for the first column can either be 'Product name', 'Product Name'or 'name
    let rowName = data['FormulationID' as any];
    return rowName?.toString();
  };

  const validateRowNames = (data: any) => {
    let atLeastIngredient = true;
    let atLeastOutcome = true;
    let atLeastProduct = true;
    let id = false;
    let type = false;
    Object.keys(data).map((key: string) => {
      if (!id) {
        id = data[key] == 'FormulationID';
      }
      if (!type) {
        type = data[key] == 'Type';
      }
      if (!atLeastIngredient) {
        atLeastIngredient = data[key].startsWith('I_');
      }
      if (!atLeastOutcome) {
        atLeastOutcome = data[key].startsWith('O_');
      }
      if (!id && !type && !atLeastIngredient && !atLeastOutcome) {
        atLeastProduct = data[key].startsWith('P_');
      }
    });
    return atLeastIngredient && atLeastOutcome && id && type && atLeastProduct;
  };

  const validateIfEmptyValues = (record: any) => {
    return Object.keys(record).filter(k => !record[k]);
  };

  const validateBounds = () => {
    const mandatory = new Set(['FormulationID', 'Type']);

    const names = [...rowNames].filter(e => !mandatory.has(e));
    console.log(names);
    const dict: { [value: string]: string[] } = {};

    for (const name of names) {
      const lowerAndUpperSame = auxFormulationsFromCsv
        .map(f => f[name])
        .every((e, _, arr) => arr[0] === e);

      if (lowerAndUpperSame) {
        return {
          isValid: false,
          where: name,
        };
      }
    }

    return {
      isValid: true,
      where: '',
    };
  };

  const props: UploadProps = {
    name: 'file',
    accept: '.csv',
    multiple: false,
    // progress: { type: 'line', strokeWidth: 2 },
    customRequest: ({ onSuccess, onError, file }) => {
      const formulationsRaw: IFormulationRaw = {
        orgId: user?.organizationId!,
        projectId: projectId,
        formulations: auxFormulationsFromCsv,
        fileName: (file as RcFile).name,
      };
      uploadFormulationRaw.mutate(formulationsRaw, {
        onSuccess: async response => {
          enableNextStep(true);
          projectHasData(true);
          setNoData(false);
          onSuccess ? onSuccess(response) : '';
        },
        onError: async (response: any) => {
          enableNextStep(false);
          message.error(response.data.message);
          onError ? onError(response) : '';
        },
      });
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file: RcFile) => {
      return validateFile(file);
    },
  };

  const handleDownloadData = () => {
    refetch();
  };

  useEffect(() => {
    if (data) {
      const blob = new Blob([data], {
        type: 'application/csv',
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.download = 'template.csv';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }, [data]);

  return (
    <div id="project-upload-data-box">
      <div className="choose-options-box">
        <h1 className="title">
          Please share inputs and outcomes details for this project.
        </h1>
        <div className="options">
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio
                value={'upload'}
                className={`${value === 'upload' ? '' : 'unchecked'}`}
              >
                Upload your own data{' '}
                <a onClick={handleDownloadData} target="_blank">
                  Download template
                </a>
              </Radio>
              <Radio
                value={'no-data'}
                className={`${value === 'no-data' ? '' : 'unchecked'}`}
              >
                No Data
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="upload-data-options">
          <h1 className="title">Past formulations</h1>
          <div className="upload-data-formulations">
            <Dragger {...props} disabled={value !== 'upload'}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint watermark">
                Accepts single CSV files
              </p>
              <p className="ant-upload-text">
                Upload past formulation data file related to this product or
                download the sample file to update inputs and outcomes
                information.
              </p>
            </Dragger>
          </div>
        </div>
      </div>
    </div>
  );
};
