import { ProductFeatureType } from "@prisma/client";
import { BaseClient } from "../ApiClient";

const ENDPOINTS = {
  getProductFeatures: () =>
    `/product-features`,
};


const getProductFeatures = (parameters: any) => {
  return BaseClient.get<{ [key in ProductFeatureType]: boolean }>(
    ENDPOINTS.getProductFeatures()
  );
};


export const ProductFeatureRepository = {
  getProductFeatures
};