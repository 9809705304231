import { BaseClient } from '../ApiClient';
import { OrgIdProjId, OrgIdProjIdOutputId, PutRawDataOut } from '../interfaces/rawdata.interface';
import { TestPlanOut } from '../interfaces/test-plan.interface';

const ENDPOINTS = {
  deleteTestPlan: (orgId: string, projectId: string, testPlanId: string) =>
    `org/${orgId}/project/${projectId}/test-plan/${testPlanId}`,
  postValidateRangesUrl: (org_id: string, proj_id: string) =>
    `org/${org_id}/project/${proj_id}/test-plan/validateRanges`,
};

const deleteTestPlan = (
  data: {
    orgId: string,
    projectId: string,
    testPlanId: string,
  }) => {
  return BaseClient.delete(
    ENDPOINTS.deleteTestPlan(data.orgId, data.projectId, data.testPlanId))
}

const validateRanges = (data: TestPlanOut) => {
  return BaseClient.post(
    ENDPOINTS.postValidateRangesUrl(data.organizationId, data.projectId),
    data
  );
};


export const TestPlanRepository = {
  validateRanges,
  deleteTestPlan
};

