/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import { DraggerProps, RcFile } from 'antd/lib/upload';
import {
  CheckCircleOutlined,
  InboxOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { parse } from 'papaparse';
import { css } from '@emotion/react';
import { FileUpload } from '../../../_shared/components/data/file-upload.component';
import {
  TestPlanWithFormulationsType,
  useSession,
} from '../../../_shared/context';
import { useprocessTestPlanUploadMutation } from '../../../../../__generated__/globalTypes';

type ModalProps = {
  testPlan: TestPlanWithFormulationsType;
  open: boolean;
  setOpenUploadModal: (o: boolean) => void;
  handleOnUploadComplete: (fileName: string) => void;
};

const rowNames = new Set<String>(['Internal ID', 'Turing ID', 'Cost']);
const outcomeNames = new Set<string>();

export const UploadTestPlanResultsModal = ({
  testPlan,
  open,
  setOpenUploadModal,
  handleOnUploadComplete,
}: ModalProps) => {
  const { currentProject } = useSession();

  testPlan?.formulations.forEach(formulation =>
    formulation.items.forEach(item => rowNames.add(item.variable.name))
  );

  currentProject?.activeModel?.outcomes.forEach(o =>
    outcomeNames.add(o.targetVariable)
  );

  const [initUpload, setInitUpload] = useState<boolean>(false);
  const [fileData, setFileData] = useState<RcFile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedSuccessfully, setUploadedSuccessfully] = useState<boolean>(
    false
  );
  const [fileHasErrors, setFileHasErrors] = useState<boolean>(false);

  const draggerProps: DraggerProps = {
    accept: '.csv',
    name: 'file',
    multiple: false,
    height: 300,
    onChange: info => {
      const { status } = info.file;
      if (status === 'error') {
        void message.error(`${info.file.name} file upload failed.`);
        throw new Error(`${info.file.name} file upload failed.`);
      }
    },
  };

  let csvData: number = 0;
  let invalidRowNames: string[] = [];
  const validateFile = (file: RcFile) => {
    parse<Array<string>>(file, {
      header: true,
      skipEmptyLines: 'greedy',
      step: results => {
        const { data } = results;

        let rowName: string | string[] = getResultsRowName(data);
        const isValid = validateRowNames(rowName);

        if (!isValid) {
          invalidRowNames.push(rowName);
        } else {
          const rowData = Object.entries(data).filter((val, idx) => idx !== 0);
          let cellCount = 0;
          rowData.forEach(rd => {
            if (rd[1]) cellCount++;
          });

          if (cellCount > 0) csvData++;
        }
      },
      complete: results => {
        const { errors } = results;
        if (csvData < 1) {
          setFileHasErrors(true);
          void message.error(
            `Empty file detected. Please upload a file with values for at least one formulation.`
          );
          return;
        }

        if (invalidRowNames.length > 0) {
          setFileHasErrors(true);
          void message.error(
            `Attribute${invalidRowNames.length === 1 ? '' : 's'
            } '${invalidRowNames.join(
              ', '
            )}' not recognized in this project. Please download the CSV file template and try again.`
          );
          invalidRowNames = [];
          return;
        }

        if (errors.length === 0) {
          setFileData(file);
          setFileHasErrors(false);
          setInitUpload(true);
        } else {
          setFileHasErrors(true);
          void message.error(
            `Error parsing file. Please upload a valid CSV file.`
          );
        }
      },
    });
  };

  const resetModal = () => {
    setUploadedSuccessfully(false);
    setFileData(undefined);
    setOpenUploadModal(false);
    setInitUpload(false);
  };

  const handleFileUploadSuccess = (res: any, file: RcFile) => {
    setUploadedSuccessfully(true);
    handleOnUploadComplete(file.name);
  };

  const handleUploadError = (e: unknown) => {
    setIsLoading(false);
    setFileHasErrors(true);
  };

  const DefaultView = (
    <React.Fragment>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-p">Drag a file to this area to upload</p>
      <p className="ant-upload-hint">Accepts Single CSV files</p>
    </React.Fragment>
  );

  const SuccessView = (
    <div
      css={css`
        text-align: center;
        padding-top: 20px;
      `}
    >
      <CheckCircleOutlined style={{ fontSize: '100px' }} />

      <h3
        css={css`
          margin-top: 15px;
          margin-bottom: 15px;
        `}
      >
        Data Upload Successful!
      </h3>

      <p>Your data has been uploaded and is being processed.</p>
      <Button
        type="primary"
        size={'large'}
        css={css`
          margin-top: 15px;
        `}
        onClick={resetModal}
      >
        Close
      </Button>
    </div>
  );

  const LoadingView = (
    <div>
      <LoadingOutlined
        css={css`
          font-size: 100px;
          margin-bottom: 10px;
        `}
        spin
      />
      <p>Uploading file...</p>
    </div>
  );

  return (
    <Modal
      title={
        uploadedSuccessfully ? 'File Uploaded' : 'Upload Test Plan Results'
      }
      centered
      width={600}
      destroyOnClose
      styles={{
        body: {
          minHeight: 400,
        },
      }}
      open={open}
      onCancel={resetModal}
      footer={
        uploadedSuccessfully
          ? null
          : [
            <Button onClick={() => setOpenUploadModal(false)} key="back">
              Cancel
            </Button>,
          ]
      }
    >
      {uploadedSuccessfully ? (
        SuccessView
      ) : (
        <React.Fragment>
          <p>
            After you fill out new data values for each formulation in the test
            plan, save it and upload the csv back to the platform.
          </p>
          <FileUpload
            uploadType={'dragger'}
            keyScope="test_plan"
            doUpload={initUpload}
            onFileSelected={validateFile}
            onUploadSuccess={handleFileUploadSuccess}
            onError={handleUploadError}
            {...draggerProps}
          >
            <React.Fragment>
              {(!fileData || fileHasErrors) && DefaultView}
              {isLoading && !fileHasErrors && LoadingView}
            </React.Fragment>
          </FileUpload>
        </React.Fragment>
      )}
    </Modal>
  );
};

const validateRowNames = (rowName: string | undefined) => {
  let inBaseRowNames = false;
  let inOutcomeNames = false;

  if (rowName) {
    inBaseRowNames = rowNames.has(rowName);
    outcomeNames?.forEach(o => {
      if (rowName.includes(o)) inOutcomeNames = true;
    });
  }

  return inBaseRowNames || inOutcomeNames;
};

const getResultsRowName = (data: string[][]) => {
  // The header for the first column can either be 'Product name', 'Product Name'or 'name
  let rowName = data['Product name' as any];

  if (data['Name' as any]) {
    rowName = data['Name' as any];
  }

  if (data['Product Name' as any]) {
    rowName = data['Product Name' as any];
  }

  return rowName?.toString();
};
