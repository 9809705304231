import { BaseClient } from '../ApiClient';

const ENDPOINTS = {
  getCSVTemplate: () =>
    `file/template/historicaldata`,
  getTestPlanUpload: (testPlanUploadId: string) =>
    `file/testPlanUpload/${testPlanUploadId}`
};

const getCSVTemplate = (data: any) => {
  return BaseClient.get(
    ENDPOINTS.getCSVTemplate(),
    data
  );
};

const getTestPlanUpload = (parameters: any): Promise<any> => {
  return BaseClient.get(
    ENDPOINTS.getTestPlanUpload(parameters.queryKey[1])
  );
};


export const FileRepository = {
  getCSVTemplate,
  getTestPlanUpload
};
