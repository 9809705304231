import React from "react"

export const TuringUniversityWrapper: React.FC = () => {
  return (
    <div>
      <iframe
        src="https://turingsaasportal.usetiful.help/"
        style={{ width: '100%', height: '90vh', border: 'none' }}
        title="university-portal"
      />
    </div>
  )
}