/** @jsxImportSource @emotion/react */

import { Avatar, List } from "antd";
import './messageItem.less';
import { IMessage, MessageType } from "../../../../_shared/types";

type IProps = {
  item: IMessage,
  id: number,
}

export const MessageItem = ({ item, id }: IProps) => {
  const css = item.userType === MessageType.SUPPORT ? `list-item-inverted` : `list-item`;
  const [firstName, lastName] = item.userName.split(" ");

  const avatar = (<Avatar shape="circle" >
    {firstName?.charAt(0)?.toLocaleUpperCase()}
    {lastName?.charAt(0)?.toLocaleUpperCase()}
  </Avatar>)
  //(<Avatar src={item.avatar} />);
  //item.userType === MessageType.SUPPORT ?
  //:

  //   <Avatar shape="circle" >
  //     {firstName?.charAt(0)?.toLocaleUpperCase()}
  //     {lastName?.charAt(0)?.toLocaleUpperCase()}
  //   </Avatar>
  // )

  return (
    <>
      <List.Item key={id} className={css}>
        <List.Item.Meta
          className={String(id)}
          avatar={avatar}
          title={item.userName}
          description={item.message}
          key={id}
        />

      </List.Item>
    </>)

}


