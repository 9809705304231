import React, { FC, useEffect, useState } from 'react';
import './edit-data.less';
import {
  Button,
  Drawer,
  Flex,
  message,
  Radio,
  Space,
  Table,
  TableProps,
  Tabs,
  Typography,
  UploadProps,
} from 'antd';
import {
  ConstraintType,
  FormulationSource,
  Outcome,
  useformulationsListLazyQuery,
} from '../../../../../../__generated__/globalTypes';
import {
  useInputOutpuNamesContext,
  useSession,
} from '../../../../_shared/context';
import { RadioChangeEvent } from 'antd/lib';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { parse } from 'papaparse';
import {
  useDeleteFormulationRaw,
  useGetProjectFormulationsRaw,
} from '../../../../network/services/project.service';
import {
  IBaseFormulationRaw,
  IFormulationRaw,
} from '../../../../network/interfaces/formulationRaw.interfaces';
import { DownloadFile } from '../../../../_shared/components/button/download-file';
import { getFormulationsExport } from '../../../../components/workspaces/adaptive-learning/design-utils';
import {
  roundFormulations,
  useFormulations,
} from '../../../../_shared/context/formulations-context';
import { getProjectSetupFormulationsExport } from '../../../../_shared/utils/util';

export const emptyConstraint = {
  id: '',
  constraintType: ConstraintType.EQUALITY,
  lowerBounds: null,
  upperBounds: null,
  coefficients: [],
  values: [],
  variables: [],
};

export const ProjectEditDataStep = ({
  projectId,
  enableNextStep,
  setNoData,
  handleNextStep,
}: {
  projectId: string;
  enableNextStep: (value: boolean) => void;
  setNoData: (value: boolean) => void;
  handleNextStep: () => void;
}) => {
  const {
    user,
    setCurrentProject,
    currentProject,
    getSelectedIteration,
  } = useSession();
  const [formulationRaw, setFormulationRaw] = useState([]);
  const [columnsTable, setColumnsTable] = useState<TableProps<any>['columns']>(
    []
  );
  const [formulationsDataTable, setformulationsDataTable] = useState<any>([]);
  const deleteFormulationRaw = useDeleteFormulationRaw();
  const {
    data,
    isLoading,
    isSuccess,
    refetch,
    isError,
  } = useGetProjectFormulationsRaw({
    projectId: projectId,
    organizationId: user?.organizationId,
  });

  const { unroundedFormulations } = useFormulations();

  useEffect(() => {
    if (data && data.status) {
      const formulations = data.data;
      setFormulationRaw(formulations);
      if (formulations?.length > 0) {
        const formulation = formulations[0];

        const columns: TableProps<any>['columns'] = [
          {
            title: 'FormulationID',
            dataIndex: 'FormulationID',
            key: 'FormulationID',
          },
          {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            sorter: (a, b) => a.Type.localeCompare(b.Type),
            filters: [
              { text: 'Benchmark', value: 'Benchmark' },
              { text: 'Past Formulation', value: 'Past Formulation' },
              { text: 'Competitive', value: 'Competitive' },
              { text: 'Tested', value: 'Tested' },
            ],
            onFilter: (value, record) => record.Type === value,
          },
        ];

        formulation.formulationItemsRaw.map((fi: any) => {
          columns.push({
            title: fi.name,
            dataIndex: fi.name,
            key: fi.name,
          });
        });

        columns.push(
          {
            title: 'Source File',
            dataIndex: 'sourceFileName',
            key: 'sourceFileName',
          },
          {
            title: 'Uploaded At',
            dataIndex: 'uploadedAt',
            key: 'uploadedAt',
          }
        );

        columns.push({
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a
                onClick={() => {
                  handleDelete(record.id);
                }}
              >
                Delete {record.name}
              </a>
            </Space>
          ),
        });
        setColumnsTable(columns);
        const dataTable: any[] = [];
        formulations.map((f: any) => {
          let row: any = {
            id: f.id,
            FormulationID: f.name,
            Type: f.type,
          };

          f.formulationItemsRaw.map((fi: any) => {
            row[fi.name] = fi.value;
          });

          if (f.csvUploadFile) {
            row['sourceFileName'] = f.csvUploadFile.name;
            row['uploadedAt'] = (
              <Typography.Text ellipsis={true}>
                {new Date(f.csvUploadFile.createdAt).toLocaleDateString()} by{' '}
                {f.csvUploadFile.createdBy.firstName}{' '}
                {f.csvUploadFile.createdBy.lastName}
              </Typography.Text>
            );
          }
          dataTable.push(row);
        });

        const testedFormulations = unroundedFormulations.filter(
          formulation => formulation.source === FormulationSource.TESTED
        );

        testedFormulations.map(f => {
          let row: any = {
            id: f.id,
            FormulationID: f.key,
            Type: 'Tested',
          };

          f.items.map(fi => {
            row[fi.variable.name] = fi.value;
          });

          row['sourceFileName'] = 'Test Plan Upload';
          row['uploadedAt'] = (
            <Typography.Text ellipsis={true}>
              {new Date(f.createdAt).toLocaleDateString()}
            </Typography.Text>
          );

          dataTable.push(row);
        });

        setformulationsDataTable(dataTable);
      }
    }
  }, [data, unroundedFormulations]);

  const handleDelete = (formulationId: string) => {
    const formulationsRaw: IFormulationRaw = {
      orgId: user?.organizationId!,
      projectId: projectId,
      formulationId: formulationId,
    };
    deleteFormulationRaw.mutate(formulationsRaw, {
      onSuccess: async response => {
        refetch();
      },
      onError: async (response: any) => {
        message.error(response.data.message);
      },
    });
  };

  return (
    <Flex vertical gap={12}>
      {unroundedFormulations.length > 0 && (
        <div style={{ marginTop: 12 }}>
          <DownloadFile
            options={{
              type: 'data:attachment/text',
            }}
            content={getProjectSetupFormulationsExport(
              formulationRaw,
              unroundedFormulations.filter(
                formulation =>
                  formulation.source === FormulationSource.EXISTING ||
                  formulation.source === FormulationSource.TESTED
              ),
              {
                removeOutcomeValues: false,
                includeOutcomeBounds: false,
                outcomes: currentProject?.activeModel?.outcomes as
                  | Outcome[]
                  | undefined,
              }
            )}
            filename={`${currentProject?.key}-all-formulations.csv`}
          >
            <Button>Download All Data</Button>
          </DownloadFile>
        </div>
      )}

      <div id="project-edit-data-box">
        {columnsTable && formulationsDataTable && (
          <Table columns={columnsTable} dataSource={formulationsDataTable} />
        )}
      </div>
    </Flex>
  );
};
