import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { useSession } from './session-context';
import {
  WorkspaceType,
  getTestPlansByIterationIdQuery,
  usefindIterationWithLatestSimulationLazyQuery,
  usegetTestPlansByIterationIdLazyQuery,
} from '../../../../__generated__/globalTypes';
import { IterationWithLatestSimulation } from '../../components/workspaces/lab-bench/context';
import { useParams } from 'react-router-dom';
import { DesignRouteParams } from '../../components/workspaces/adaptive-learning/types';
import useWebSocket from '../hooks/use-web-socket.hook';

interface ExecuteSolutionsContextProps {
  selectedIteration: IterationWithLatestSimulation | undefined;
  setSelectedIteration(selectedIteration: IterationWithLatestSimulation): void;
  selectedDesignId?: string;
  setSelectedDesignId(selectedDesignId: string): void;
  getTestPlansWithFormulations(iterationId: string): Promise<void>;
  testPlansWithFormulations: TestPlanWithFormulationsType[];
  setTestPlansWithFormulations(
    updatedTestPlans: TestPlanWithFormulationsType[]
  ): void;
}

export type TestPlanWithFormulationsType = getTestPlansByIterationIdQuery['getTestPlansByIterationId'][0];

const ExecuteSolutionsContext = React.createContext<
  ExecuteSolutionsContextProps
>({} as any);
export const ExecuteSolutionsContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const {
    currentProject,
    selectedIterationId,
    setSelectedIterationId,
  } = useSession();
  const params = useParams<DesignRouteParams>();
  const [selectedIteration, setSelectedIteration] = useState<
    IterationWithLatestSimulation | undefined
  >();
  const [selectedDesignId, setSelectedDesignId] = useState<string | undefined>(
    params.designId
  );
  const [getIteration] = usefindIterationWithLatestSimulationLazyQuery();
  const [getTestPlansByIterationId] = usegetTestPlansByIterationIdLazyQuery();
  const [testPlansWithFormulations, setTestPlansWithFormulations] = useState<
    TestPlanWithFormulationsType[]
  >([]);
  const { loadTestPlan } = useWebSocket();

  const getIterationWithSimulations = async (iterationId: string) => {
    const latestIterationWithSimulations = await getIteration({
      variables: {
        id: iterationId,
      },
    });

    if (latestIterationWithSimulations.data?.iteration) {
      setSelectedIteration(latestIterationWithSimulations.data.iteration);
    }
  };

  const getTestPlansWithFormulations = async (iterationId: string) => {
    const testPlansResponse = await getTestPlansByIterationId({
      variables: {
        iterationId,
      },
    });

    if (testPlansResponse.data?.getTestPlansByIterationId) {
      let testPlansFiltered = testPlansResponse.data.getTestPlansByIterationId.filter((tp) => !tp?.campaign?.isSoftDeleted)
      setTestPlansWithFormulations(
        testPlansFiltered
      );
    }
  };

  useEffect(() => {
    if (!selectedIteration) {
      const latestIteration = currentProject?.iterations.filter(
        iteration => iteration.type === WorkspaceType.EXPLORATION
      )[0];

      if (latestIteration) {
        setSelectedIterationId(latestIteration.id);
        getIterationWithSimulations(latestIteration.id);
        getTestPlansWithFormulations(latestIteration.id);
      }
    }
  }, [currentProject?.iterations, loadTestPlan]);

  useEffect(() => {
    if (selectedIterationId) {
      getIterationWithSimulations(selectedIterationId);
      getTestPlansWithFormulations(selectedIterationId);
    }
  }, [selectedIterationId, loadTestPlan]);

  return (
    <ExecuteSolutionsContext.Provider
      value={{
        selectedIteration,
        setSelectedIteration,
        selectedDesignId,
        setSelectedDesignId,
        getTestPlansWithFormulations,
        testPlansWithFormulations,
        setTestPlansWithFormulations,
      }}
    >
      {children}
    </ExecuteSolutionsContext.Provider>
  );
};

export const useExecuteSolutions = () => useContext(ExecuteSolutionsContext);
