import { InfoCircleOutlined } from "@ant-design/icons";
import { useSession } from "../../../_shared/context";
import { calculateFormulationCostV2 } from "../../../_shared/utils/util";
import { usegetSimulationInputForIterationQuery } from "__generated__/globalTypes";
import { Select, Tooltip as AntTooltip, Flex } from "antd"
import React, { useEffect, useState } from "react"
import { CartesianGrid, Label, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts"
import _ from "lodash";
import { FormulationSelected } from "../formulation-item/formulation-item";
import './table-desirability-score.less';
import { FormulationType } from "../../../_shared/context/formulations-context";
import { useGetFormulationGoal } from "../../../network/services/goal.service";

interface ScorePlotData {
  name: string,
  cost: number,
  value: number,
  desirability: number,
  fill: string
}

const CustomTooltip = ({ position, payload, content, onMouseLeave, onMouseEnter }: any) => {
  const { x, y } = position || {};
  const data = payload?.payload
  return (
    <div
      className="custom-tooltip"
      style={{ left: x + 5, top: y + 5 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div>
        <p><b>Initiative: </b> {data?.formulation?.initiative}</p>
        <p><b>Round: </b> {data?.formulation?.round + 1}</p>
        <p><b>Name: </b>{data?.name}</p>
        <p>{`Cost: ${data?.cost}`}</p>
        <p>{`Desirability: ${data?.desirability}`}</p>
        {Object.keys(data).map((key) => {
          if (!['name', 'cost', 'value', 'desirability', 'fill', 'formulation'].includes(key)) {
            return <p key={key}>{`${key}: ${data[key]}`}</p>;
          }
          return null;
        })}
      </div>
    </div>
  );
};


export const TableDesairabilityScore = ({ goalDesirability }: { goalDesirability: any }) => {
  const { user, currentProject } = useSession();
  const [scorePlotData, setScorePlotData] = useState<ScorePlotData[]>([])

  const [selectedInitiativesScorePlot, setSelectedInitiativesScorePlot] = useState<any[]>([]);
  const [selectedXAxis, setSelectedXAxis] = useState<any>('cost');
  const [selectedYAxis, setSelectedYAxis] = useState<any>('desirability');
  const [payload, setPayload] = useState<any>();
  const [showFormSelected, setShowFormSelected] = useState<boolean>(false);
  const [formSelected, setFormSelected] = useState<FormulationType>();

  const [formulationParams, setFormulationParams] = useState<any>({
    organizationId: undefined, projectId: undefined, formId: undefined
  });
  const { data: formulation, isSuccess, refetch: getFormulation, isLoading } = useGetFormulationGoal(formulationParams);

  const [tooltip, setTooltip] = useState({});
  let tooltipTimeout: any;
  const showTooltip = (item: any, i: any, e: any) => {
    clearTimeout(tooltipTimeout);
    setTooltip({
      show: true,
      position: { x: e.clientX, y: e.clientY },
      payload: item
    });
  };

  const hideTooltip = (e) => {
    tooltipTimeout = setTimeout(() => setTooltip({ show: false, ...e }), 200);
  };

  useEffect(() => {
    if (goalDesirability) {
      const data = goalDesirability.data;

      const formulationsDesign = data.formulations;
      let aux: ScorePlotData[] = []
      for (let key of Object.keys(formulationsDesign)) {
        selectedInitiativesScorePlot.push(key);
        const design = formulationsDesign[key];
        formatChart(design, aux);
      }
      setScorePlotData(aux)
      let aux2 = [...selectedInitiativesScorePlot];
      setSelectedInitiativesScorePlot(aux2);
    }
  }, [goalDesirability])

  useEffect(() => {
    if (goalDesirability) {
      const data = goalDesirability.data;
      const formulationsFromDesign = data.formulations;
      let aux: ScorePlotData[] = []
      for (let key of Object.keys(formulationsFromDesign)) {
        if (selectedInitiativesScorePlot.includes(key)) {
          const design = formulationsFromDesign[key];
          formatChart(design, aux);
        }
      }
      setScorePlotData(aux)
    }
  }, [selectedInitiativesScorePlot, selectedXAxis, selectedYAxis])

  const formatChart = (design: any, aux: ScorePlotData[]) => {
    design.forEach((d: any, index: number) => {
      let quantities: { name: string; value: number; }[] = [];
      Object.keys(d.quantities).map(key => {

        const i = currentProject?.ingredientList.find((i) => key === i.ingredient.id.toString())
        quantities.push({
          value: Number(d.quantities[key]),
          name: i?.ingredient.name ?? key
        })
      });
      let cost = calculateFormulationCostV2(quantities, currentProject?.ingredientList ?? []);

      const obj: any = {
        name: d.key,
        desirability: d.desirability,
        cost: cost ?? 0,
        formulation: d,
        fill: index === 0 ? '#EF4136' : '#505C65', // check if is the highest of its round
      };

      d.formulationItems.forEach((o: any) => {
        obj[o.variable.name] = o.value;
      });

      aux.push(obj);
    });
  }

  const handleChangeScorePlotFilter = (value: string[]) => {
    setSelectedInitiativesScorePlot(value);
  };
  const handleXAxis = (value: string) => {
    setSelectedXAxis(value)
  }
  const handleYAxis = (value: string) => {
    setSelectedYAxis(value)
  }

  const getFormulationIdByKey = (targetKey: string) => {
    for (const formulationId in goalDesirability.data.formulations) {
      const formulation = goalDesirability.data.formulations[formulationId];
      const item = formulation.find(obj => obj.key === targetKey);

      if (item && item.formulationItems && item.formulationItems.length > 0) {
        return item.formulationItems[0].formulationId;
      }
    }
    return null;
  }

  const onHandleClick = (e) => {
    setPayload(e)
    setFormulationParams({
      organizationId: user?.organizationId, projectId: currentProject?.id, formId: getFormulationIdByKey(e.name)
    })
    getFormulation();
    setShowFormSelected(true)
  }

  useEffect(() => {
    if (formulation?.data) {
      setFormSelected(formulation?.data)
    }
  }, [formulation])


  return (
    <div className="table-panel table-desirability">
      <div className="table-container scatter-chart ">
        <div className="scatter-chart-wrapper" >

          {goalDesirability && <>
            <div className="initiative">
              <AntTooltip placement="top" title={<div className='look-formulation' >Pick initiatives to compare their outcomes.</div>} >
                <label>Initiatives <InfoCircleOutlined />: </label>
              </AntTooltip>
              <Select
                mode="multiple"
                placeholder="Initiatives to show"
                style={{ width: 310 }}
                maxTagCount={2}
                onChange={handleChangeScorePlotFilter}
                value={selectedInitiativesScorePlot}
              >
                {Object.keys(goalDesirability.data.formulations).map((key) => (
                  <Select.Option key={key} value={key}>
                    {goalDesirability.data.formulations[key][0].initiative}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div style={{ marginTop: 30, display: 'flex', gap: 20, alignItems: 'center' }}>
              <AntTooltip placement="top" title={<div className='look-formulation' >Pick an outcome to compare.</div>} >
                <label>X Axis <InfoCircleOutlined />: </label>
              </AntTooltip>
              <Select
                placeholder="X AXIS"
                style={{ width: 300 }}
                onChange={handleXAxis}
                value={selectedXAxis}
              >
                <Select.Option key={1} value='cost'>
                  Cost
                </Select.Option>
                <Select.Option key={2} value='desirability'>
                  Desirability
                </Select.Option>
                {goalDesirability.data?.objectives?.objectives?.map((o: any) => (
                  <Select.Option key={o.targetVariable} value={o.tarjetVariable}>
                    {o.targetVariable}
                  </Select.Option>
                ))}
              </Select>
              <AntTooltip placement="top" title={<div className='look-formulation' >Pick an outcome to compare.</div>} >
                <label>Y Axis <InfoCircleOutlined />: </label>
              </AntTooltip>
              <Select
                placeholder="Y AXIS"
                style={{ width: 300 }}
                onChange={handleYAxis}
                value={selectedYAxis}
              >
                <Select.Option key={1} value='cost'>
                  Cost
                </Select.Option>
                <Select.Option key={2} value='desirability'>
                  Desirability
                </Select.Option>
                {goalDesirability.data?.objectives?.objectives?.map((o: any) => (
                  <Select.Option key={o.targetVariable} value={o.tarjetVariable}>
                    {o.targetVariable}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </>
          }


          <div className="chart-container">
            <ResponsiveContainer width="100%" height="100%" className={"responsive-container-wrapper"}>
              <ScatterChart
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <Legend />
                <CartesianGrid
                  strokeDasharray="3 3"
                />
                <XAxis
                  dataKey={selectedXAxis}
                  type="number"
                  name={selectedXAxis}
                  style={{ textTransform: 'capitalize' }}
                  stroke="#C7C7C7"
                  label={{ value: _.capitalize(selectedXAxis), position: 'insideBottomRight', offset: -10 }}
                  domain={['dataMin - 1', 'dataMax + 1']}
                  tickFormatter={(value) => value.toFixed(1)}
                  allowDecimals
                  allowDataOverflow
                  interval={0}
                />
                <YAxis
                  dataKey={selectedYAxis}
                  type="number"
                  name={selectedYAxis}
                  stroke="#C7C7C7"
                  label={{
                    value: _.capitalize(selectedYAxis), angle: -90, position: 'insideLeft', offset: -10
                  }}
                  tickFormatter={(value) => value.toFixed(2)}
                >
                </YAxis>
                <Scatter
                  name="Best Desirability per round"
                  fill='#EF4136'
                  color='black'
                  data={scorePlotData}
                  // onMouseEnter={showTooltip}
                  // onMouseLeave={hideTooltip}
                  onClick={onHandleClick}
                />

              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      {(formSelected && showFormSelected) && (
        <div className='formulation-container table-desirability'>
          <FormulationSelected formulation={formSelected} onHandleFormulation={setShowFormSelected} performance={payload} />
        </div>
      )}
      {/* {
        tooltip.show && (
          <CustomTooltip
            onMouseEnter={() => {
              clearTimeout(tooltipTimeout);
            }}
            onMouseLeave={() => {
              setTooltip({ show: false });
              clearTimeout(tooltipTimeout);
            }}
            position={tooltip.position}
            payload={tooltip.payload}
          />
        )
      } */}

    </div >
  )
}


