/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Popover, Flex, Input, Button, notification } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { usecreateOneTestPlanMutation } from '../../../../../__generated__/globalTypes';
import {
  TestPlanWithFormulationsType,
  useExecuteSolutions,
} from '../../../_shared/context';
import { DropTargetTestPlanComponent } from './drop-target-test-plan';
import { ResultsSummaryComponent } from './results-summary';

export const TestPlanComponent = ({
  handleOnFormulationDrop,
  handleOnReviewTestPlan,
}: {
  handleOnFormulationDrop(
    formulationKey: string,
    testPlanId: string,
    action: 'ADD' | 'REMOVE'
  ): void;
  handleOnReviewTestPlan(selectedTestPlan: TestPlanWithFormulationsType): void;
}) => {
  const {
    selectedIteration,
    testPlansWithFormulations,
    setTestPlansWithFormulations,
  } = useExecuteSolutions();
  const [showSidebar, setShowSidebar] = useState(true);
  const [showNewTestPlanPopover, setShowNewTestPlanPopover] = useState(false);
  const [newTestPlanName, setNewTestPlanName] = useState('');
  const [createTestPlan, { loading }] = usecreateOneTestPlanMutation();
  const [testPlanSummaryToShow, setTestPlanSummaryToShow] = useState<
    TestPlanWithFormulationsType | undefined
  >();

  const [collapsedTestPlans, setCollapsedTestPlans] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (
      Object.entries(collapsedTestPlans).length === 0 &&
      testPlansWithFormulations.length > 0
    ) {
      const updatedCollapsedTestPlans = testPlansWithFormulations.reduce(
        (acc, item, index) => {
          if (!item) return acc;

          acc[item.id] = index === 0;
          return acc;
        },
        {} as Record<string, boolean>
      );
      
      setCollapsedTestPlans(updatedCollapsedTestPlans);
    }
  }, [testPlansWithFormulations, collapsedTestPlans]);

  const toggleTestPlan = (testPlanId: string) => {
    setCollapsedTestPlans(prevState => ({
      ...prevState,
      [testPlanId]: !prevState[testPlanId],
    }));
  };

  const handleCreateNewTestPlan = async () => {
    try {
      if (newTestPlanName === '') {
        notification.error({ message: 'Test Plan name is required' });
      } else {
        const createTestPlanResponse = await createTestPlan({
          variables: {
            iterationId: selectedIteration?.id!,
            name: newTestPlanName,
          },
        });

        if (createTestPlanResponse.data?.createOneTestPlan) {
          setTestPlansWithFormulations([
            ...testPlansWithFormulations,
            createTestPlanResponse.data.createOneTestPlan,
          ]);

          setShowNewTestPlanPopover(false);
          setNewTestPlanName('');
        }
      }
    } catch (error) {
      notification.error({ message: 'Error creating Test Plan' });
    }
  };

  const CloseSidebarButton = () => (
    <div>
      <Button
        icon={
          <DownloadOutlined
            style={{
              rotate: showSidebar ? '90deg' : '-90deg',
              color: showSidebar ? '#D3D6D9' : 'inherit',
            }}
          />
        }
        onClick={() => setShowSidebar(!showSidebar)}
        type="text"
      />
    </div>
  );

  const handleTestPlanProcessingComplete = (
    updatedTestPlan: TestPlanWithFormulationsType
  ) => {
    const existingPlanIndex = testPlansWithFormulations.findIndex(
      testPlan => testPlan?.id === updatedTestPlan?.id
    );

    if (existingPlanIndex > -1) {
      const updatedPlans = [...testPlansWithFormulations];
      updatedPlans[existingPlanIndex] = updatedTestPlan;

      setTestPlansWithFormulations(updatedPlans);
      setTestPlanSummaryToShow(updatedTestPlan);
    }
  };

  if (!showSidebar) {
    return (
      <div
        css={css`
          width: 60px;
          display: flex;
          justify-content: center;
        `}
      >
        <CloseSidebarButton />
      </div>
    );
  }

  return (
    <div
      css={css`
        width: 290px;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        position: relative;
      `}
    >
      <div
        css={css`
          display: flex;
          padding: 6px 12px;
          height: 60px;
          padding-right: 0;
          border-bottom: 1px solid #f4f4f4;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          css={css`
            color: var(--Neutrals-Text, #161f26);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            white-space: nowrap;
          `}
        >
          Test plan
        </div>
        <Flex align="center">
          <Flex style={{ marginRight: 8 }}>
            <Popover
              open={showNewTestPlanPopover}
              content={
                <Flex vertical gap={12}>
                  <Input
                    value={newTestPlanName}
                    onChange={event =>
                      setNewTestPlanName(event.currentTarget.value)
                    }
                    required
                    minLength={1}
                    maxLength={50}
                  />
                  <Button
                    loading={loading}
                    onClick={() => handleCreateNewTestPlan()}
                  >
                    Add Test Plan
                  </Button>
                </Flex>
              }
              title="Create a Test Plan"
              trigger="click"
            >
              <Button
                size="small"
                icon={<PlusOutlined />}
                loading={loading}
                onClick={() =>
                  setShowNewTestPlanPopover(!showNewTestPlanPopover)
                }
              >
                Add
              </Button>
            </Popover>
          </Flex>
          <CloseSidebarButton />
        </Flex>
      </div>
      {testPlansWithFormulations.map(testPlan => (
        <DropTargetTestPlanComponent
          key={testPlan?.id}
          testPlan={testPlan}
          toggleTestPlan={toggleTestPlan}
          collapsedTestPlans={collapsedTestPlans}
          handleOnFormulationDrop={(formulationId: string) =>
            handleOnFormulationDrop(formulationId, testPlan!.id, 'ADD')
          }
          handleRemoveFormulation={(formulationId: string) =>
            handleOnFormulationDrop(formulationId, testPlan!.id, 'REMOVE')
          }
          handleTestPlanProcessingComplete={handleTestPlanProcessingComplete}
          setTestPlanSummaryToShow={setTestPlanSummaryToShow}
        />
      ))}
      {testPlanSummaryToShow && (
        <ResultsSummaryComponent
          testPlan={testPlanSummaryToShow}
          handleOnReviewTestPlan={testPlanToReview => {
            handleOnReviewTestPlan(testPlanToReview);
            setTestPlanSummaryToShow(undefined);
          }}
          handleCloseSummary={() => setTestPlanSummaryToShow(undefined)}
        />
      )}
    </div>
  );
};
